<script setup lang="ts">
import { Vue3Lottie } from 'vue3-lottie'

definePageMeta({
    layout: 'default-no-contact',
})

useHead({
    title: 'STORM - Home',
})

</script>

<script lang="ts">
function fallback(video: any) {
    var img = video.querySelector('img');
    if (img)
        video.parentNode.replaceChild(img, video);
}
</script>

<style></style>

<template>
    <div class="h-full">
        <div class="align-bottom min-h-full w-full :mt-32">
            <!-- <video class="w-full" id="storm_hero" autoplay muted loop playsinline webkit-playsinline poster="/images/STORM-hero-poster.webp">
                <source src="https://stormblob.blob.core.windows.net/videos/STORM-hero-1920x1080-short.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video> -->
            <video class="w-full" id="storm_hero_2" autoplay loop muted playsinline webkit-playsinline
                poster="/images/STORM-hero-poster.webp">
                <source src="https://stormblob.blob.core.windows.net/videos/STORM_hero_1080.mp4" type="video/mp4">
                <source src="https://stormblob.blob.core.windows.net/videos/STORM_Hero_1080.webm" type="video/webm" onerror="fallback(parentNode)">
                <img decoding="async" src="https://stormblob.blob.core.windows.net/videos/STORM_Hero_720.gif">
            </video>

            <div class="absolute top-[32vw] pb-10 pl-5 md:pl-10 w-full text-4xl sm:text-5xl lg:text-7xl font-extrabold text-white flex flex-col z-20">
                <NuxtImg src="/images/STORM-payoff-white.svg" class="w-2/5 fill-white" />
            </div>
            <client-only>
                <Vue3Lottie animation-link="/lotties/arrow-down.json" :speed="0.7"
                    class="absolute top-[43vw] sm:top-[43vw] md:top-[42vw] left-[calc(50%-3vw)] max-w-[30px] max-h-[30px] sm:max-w-[40px] sm:max-h-[40px] md:max-w-[7vw] md:max-h-[7vw] drop-shadow-lg shadow-black" />
            </client-only>
        </div>

        <div class="flex items-center justify-center w-full">
            <div class="pt-10 max-w-screen-2xl">
                <div class="grid grid-cols-1 md:grid md:grid-cols-2 items-center justify-items-center justify-evenly">
                    <ProductGroupsMVCLSCard />
                    <ProductGroupsWeaponIntegrationCard />
                </div>
            </div>
        </div>

        <div class="pt-16">
            <div class="ml-2 mr-2 md:ml-4 md:mr-4 xl:ml-44 xl:mr-44">
                <!-- <video class="w-full" id="storm_milcap" autoplay muted loop playsinline webkit-playsinline
                    poster="https://stormblob.blob.core.windows.net/videos/STORM_MilCap_720.webp">
                    <source src="https://stormblob.blob.core.windows.net/videos/STORM_MilCap_1080.mp4" type="video/mp4">
                    <source src="https://stormblob.blob.core.windows.net/videos/STORM_MilCap_1080.webm" type="video/webm">
                    Your browser does not support the video tag.
                </video> -->
                <video class="w-full" id="storm_milcap_2" autoplay loop muted playsinline webkit-playsinline
                    poster="/images/storm-milcap-felt-poster.webp">
                    <source src="https://stormblob.blob.core.windows.net/videos/STORM_MilCap_1080.mp4" type="video/mp4">
                    <source src="https://stormblob.blob.core.windows.net/videos/STORM_MilCap_1080.webm" type="video/webm" onerror="fallback(parentNode)">
                    <img decoding="async" src="https://stormblob.blob.core.windows.net/videos/STORM_MilCap_720.gif">
                </video>

            </div>
        </div>

        <div v-if="false" class="bg-white mt-20">
            <div class="ml-44 pt-16 pb-16 flex flex-col md:flex-row ">
                <div>
                    <NuxtImg src="/images/our-partners.svg" class="max-h-32 max-w-64 object-contain" />
                </div>
                <div class="justify-center md:justify-evenly md:border-l md:border-l-black md:ml-5 md:pl-5 md:mt-0 mt-12 mr-44 flex flex-wrap gap-12 h-full">
                    <div>
                        <NuxtImg src="/images/logo-akote.svg" class="max-h-32 max-w-64 object-contain" />
                    </div>
                    <div>
                        <NuxtImg src="/images/logo-nammo.svg" class="max-h-32 max-w-64 object-contain" />
                    </div>
                    <div>
                        <NuxtImg src="/images/logo-comrod.svg" class="max-h-32 max-w-64 object-contain" />
                    </div>
                    <div>
                        <NuxtImg src="/images/logo-teledyne.svg" class="max-h-32 max-w-64 object-contain" />
                    </div>
                    <div>
                        <NuxtImg src="/images/logo-ceracote.svg" class="max-h-32 max-w-64 object-contain" />
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-20">
            <HowWeWorkCard />
        </div>

        <div class="mt-20">
            <WhyStormCard />
        </div>

        <div class="w-full flex items-center justify-center my-20">
            <ContactUs />
        </div>

    </div>
</template>