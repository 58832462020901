<template>
    <div class="min-w-80 relative">
        <NuxtImg src="/images/page-1-image-5.webp" alt="" class="w-full overflow-hidden" />
        <div class="absolute flex flex-col inset-0 mt-[1vw] lg:mt-5 xl:mt-10 2xl:mt-15 start-10 lg:start-24 xl:start-32 w-1/2 text-black transition">
            <div class="p-[2vw] flex flex-col absolute left-0 right-0 text-[1.5vw] leading-[2vw] lg:text-base lg:leading-base">
                <span class="w-full font-bold text-xl sm:text-2xl md:text-3xl lg:text-[2.5vw]">
                    Why STORM
                </span>
                <hr class="mt-[3vw] mb-[3vw] lg:mt-[1.8vw] lg:mb-[1.8vw] h-px relative -start-[5vw] w-[10vw] border-black" />
                <span class="w-full lg:pr-32 text-xs sm:text-sm md:text-base lg:text-[1.4vw] lg:leading-[1.9vw] bg-white/50 lg:bg-transparent rounded-md p-2">
                    At STORM Defence, we are committed to serving the defence sector with unparalleled expertise and creativity. Our team of engineers and visionary
                    thinkers
                    collaborates to develop customized solutions that meet the specific requirements of each defence operation.
                </span>
                <NuxtLink to="/why-storm" class="text-green-500 font-semibold mt-8">
                    <button type="button"
                        class="text-white bg-green-900 hover:bg-green-950 focus:outline-none focus:ring-4 focus:ring-green-600 font-medium rounded-full text-sm w-32 py-2.5 text-center me-2 mb-2">Explore
                        &gt;</button>
                </NuxtLink>
            </div>
        </div>
    </div>
</template>